import React, { Fragment } from "react";

import { useLocation } from "react-router-dom";

import { Btn } from "../../../AbstractElements";
import Notificationbar from "./Notificationbar";
import MoonLight from "./MoonLight";
import UserHeader from "./UserHeader";
import { UL } from "../../../AbstractElements";
import save from '../../../assets/images/save.svg'


const RightHeader = ({ clickBtnSetting }) => {

  let str = useLocation().pathname;

  let btnClass = !str.includes('settingsuser') ? "d-none" : "d-block"
  let ulClass = !str.includes('settingsuser') ? "ms-auto" : ""

  return (
    <Fragment>
        <div
          className="nav-right d-flex justify-content-between right-header col-10 col-lg-12 p-0 ms-auto"
        >
          <Btn
            attrBtn={{
              color: "primary",
              className: `${btnClass} btn btn-primary btn-settings ms-auto me-3 me-sm-5`,
            }}
            onClick={clickBtnSetting}
          >
            <img src={save} className='d-flex d-sm-none' alt="" />
            <span className="d-none d-sm-block k_save_settings">Сохранить настройки</span>
          </Btn>

          <UL attrUL={{ className: `simple-list nav-menus flex-row ${ulClass}` }}>
            <MoonLight />
            <Notificationbar />
            <UserHeader />
          </UL>

        </div>
    </Fragment>
  );
};

export default RightHeader;
