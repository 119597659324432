import React, { Fragment, useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import { Btn, H5 } from "../../../AbstractElements";
import { tableColumns } from "./Defaultdata";
import { Input } from "reactstrap";
import { Link } from 'react-router-dom';

import $ from 'jquery';

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);

  const dummytabledata_arr = [];
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/get_users',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password')},
      success: function(data){
        console.log(data);
        if (data.status == 'ok'){
          for (var i=0; i<data.users.length; i++) {
              if (data.users[i].user_status == '-1' || data.users[i].user_status == -1){
                  var status = 'Разблокировать';
              }else{
                  var status = 'Заблокировать';
              }

              var status_active = <span class="badge badge-light-warning">Не активен</span>
              if (data.users[i].user_status == '1' || data.users[i].user_status == 1){
                  var status_active = <span class="badge badge-light-success k_change_badge" data-id={data.users[i].id}>Активен</span>
              }

              if (data.users[i].user_status == '-1' || data.users[i].user_status == -1){
                  var status_active = <span class="badge badge-light-danger k_change_badge" data-id={data.users[i].id}>Заблокирован</span>
              }

              if (data.users[i].user_status == '0' || data.users[i].user_status == 0){
                  var status_active = <span class="badge badge-light-warning k_change_badge" data-id={data.users[i].id}>Не активен</span>
              }



              dummytabledata_arr.push({
                  id: i,
                  id_s: data.users[i].id,
                  user: data.users[i].username,
                  get links() {
                      return <Link to={`${process.env.PUBLIC_URL}/statistics/statuser/:${this.id_s}`}>{this.user}</Link>;
                  },
                  date_reg: <div className='d-flex flex-column'>
                      <span>{data.users[i].start_date}</span>
                  </div>,
                  date_block: <div className='d-flex flex-column'>
                      <span>{data.users[i].end_date}</span>
                  </div>,
                  status: status_active,
                  status_sort: data.users[i].user_status,
                  control: <div className='d-flex gap-2'>
                      <button className='btn btn-primary col k_block_user' data-id={data.users[i].id} data-username={data.users[i].username}>{status}</button>
                  </div>
              })
          }


          console.log(data.users);
        }
      }
  });

  const [data, setData] = useState(dummytabledata_arr);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (
      window.confirm(
        `Вы уверены, что хотите удалить:\r ${selectedRows.map((r) => r.user)}?`
      )
    ) {
      setToggleDelet(!toggleDelet);

      setData(
        data.filter((item) =>
          selectedRows.filter((elem) => elem.id === item.id).length > 0
            ? false
            : true
        )
      );
      setSelectedRows("");
    }
  };
  const handleSave = () => {
    if (
      window.confirm(
        `Вы хотите скачать логи:\r ${selectedRows.map((r) => r.user)}?`
      )
    ) {
      console.log(selectedRows.map((r) => r.user));
    }
  };

  // простая фильтрация без фирменного FilterComponent
  function handleFilter(event) {
    const newData = dummytabledata_arr.filter(row => {
        return row.user.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setData(newData)
  }

  return (
    <Fragment>
      {selectedRows.length !== 0 && (
        <div
          className={`d-flex align-items-center justify-content-between bg-light-info p-2 mb-2`}
        >
          <Btn attrBtn={{ color: "warning", onClick: () => handleSave() }}>
            Скачать логи
          </Btn>
          <div className="d-flex align-items-center gap-3">
            <H5 attrH5={{ className: "text-dark m-0 d-none d-md-block" }}>
              Удалить выбранные данные..!
            </H5>
            <Btn attrBtn={{ color: "danger", onClick: () => handleDelete() }}>
              Удалить
            </Btn>
          </div>
        </div>
      )}
      <div className="d-flex gap-3 align-items-center justify-content-start">
        <div>ПОИСК</div>
        <Input type="text" onChange={handleFilter} className='filter p-l-10 p-r-10'/>
      </div>
      <DataTable
        data={data}
        columns={tableColumns}
        striped={true}
        center={true}
        fixedHeader
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelet}
      />
    </Fragment>
  );
};
export default DataTableComponent;
