import React, { Fragment, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {
  const { layoutURL } = useContext(CustomizerContext);

  // установка статуса бота три вида - success, warning, danger
  const [statusBadge, setStatusBadge] = React.useState('success')

  let str = useLocation().pathname;

  let btnInfo = str.includes('statuser') ? "ml-3 d-flex" : "d-none"
  
  return (
    <Fragment>
      <Container fluid={true}>
        <div className='page-title'>
          <Row>
            <Col xs='12' md='6' className='d-flex align-items-center gap-4'>
              <H3>{props.mainTitle}</H3>
              <div className={btnInfo}>
                <span className={`badge badge-light-success k_user_active ${statusBadge != 'success' ? 'd-none' : '' }`}>Активен</span> 
                <span className={`badge badge-light-warning k_user_none`}>Не активен</span> 
                <span className={`badge badge-light-danger k_user_block`}>Заблокирован</span>
              </div>
            </Col>
            <Col md='6' className='d-none d-md-block'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`}>
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>{props.parent}</li>
                {props.subParent ? <li className='breadcrumb-item'>{props.subParent}</li> : ''}
                <li className='breadcrumb-item active'>{props.title}</li>
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
