import React, { useState } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { CHECKALL, DateDiff, CHECKALLRU, DeliveryComplete, DeliveryCompleteRu, DeliveryProcessing, DeliveryProcessingRu, Notification, NotificationRu, OrderComplete, OrderCompleteRu, TicketsGenerated, TicketsGeneratedRu } from '../../../Constant';
import $ from 'jquery';

const Notificationbar = () => {
  const [notificationDropDown, setNotificationDropDown] = useState(false);

  $('body').on('click', '.k_clear_history_notification', function() {
    localStorage.setItem('alerts', JSON.stringify([]));
    $('.notification-list').html('');
    $('.notification-box .badge').html('0');
  })

  if (localStorage.getItem('alerts') == '' || localStorage.getItem('alerts') == null){
    localStorage.setItem('alerts', JSON.stringify([]));
  }

  var last_message = JSON.parse(localStorage.getItem('alerts'));
  last_message.reverse();
  console.log(last_message);
  const html_info = [];
  for (var i = 0; i < last_message.length; i++) {
    var last_infos = '';

    var d1 = new Date(last_message[i].time);
    var d2 = new Date();
    var dt_str = DateDiff.GenStr(d1, d2);


    if (last_message[i].type == 'info'){
      var last_infos = <span className='font-info'>{dt_str}</span>;
      var last_html = <li className='notificacion-item b-l-info border-4'><p>{last_message[i].message} {last_infos}</p></li>
    }

    if (last_message[i].type == 'success'){
      var last_infos = <span className='font-success'>{dt_str}</span>;
      var last_html = <li className='notificacion-item b-l-success border-4'><p>{last_message[i].message} {last_infos}</p></li>
    }

    html_info.push(last_html)
  }

  $('.notification-box .badge').html(i);

  return (
    <li className='onhover-dropdown'>
      <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
        <SvgIcon iconId='notification' />
        <span className='badge rounded-pill badge-secondary'>4</span>
      </div>
      <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
        <h6 className='f-18 mb-0 dropdown-title'>{NotificationRu}</h6>
        <ul className='notification-list'>
          {html_info}
          <li>
            <a className='f-w-700 k_clear_history_notification' href='#javascript'>
              {CHECKALLRU}
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
