import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { FollowersGrowthRu, WeeklyMonDropdown } from "../../../Constant";
import {
  UserChartData,
  UserChartDataResponsive,
  UserChartTimeData,
  UserChartTimeDataResponsive,
} from "../../../Data/DefaultDashboard/Chart";

import ReactApexChart from "react-apexcharts";
import DropdownCommon2 from "../../Common/Dropdown2";

const FollowerGrowth = () => {
  const updatedChartData = {
    ...UserChartData,
    options: {
      ...UserChartData.options,
      ...UserChartDataResponsive,
    },
  };

  const updatedChartTimeData = {
    ...UserChartTimeData,
    options: {
      ...UserChartTimeData.options,
      ...UserChartTimeDataResponsive,
    },
  };

  const [dropDown, setDropDown] = React.useState("в днях");
  const [userFull, setUserFull] = React.useState(false);

  const width = useWindowSize();

  React.useEffect(() => {
    setDropDown("в днях");
  }, []);

  function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
        if (window.innerWidth >= 567) {
          setUserFull(true);
        } else {
          setUserFull(false);
        }
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useWindowSize();


  function handlerActive(toogleDown) {
    setDropDown(toogleDown);
  }

  return (
    <Card className="card growth-wrap">
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            Активность <span>{userFull ? 'пользователей' : 'пользаков'}</span> <span className="h5 badge badge-light-success">{dropDown}</span>
          </H5>
          <DropdownCommon2
            dropdownMain={{ className: "icon-dropdown", direction: "start" }}
            options={WeeklyMonDropdown}
            iconName="icon-more-alt"
            btn={{ tag: "span" }}
            active={handlerActive}
          />
        </div>
      </CardHeader>
      <CardBody>
        <div className="growth-wrapper">
          <ReactApexChart
            height={325}
            type="line"
            options={(dropDown === 'в днях') ? updatedChartData.options : updatedChartTimeData.options}
            series={(dropDown === 'в днях') ? updatedChartData.series : updatedChartTimeData.series}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowerGrowth;
