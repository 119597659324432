import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";

import {
  Container,
  Row,
  Col,
} from "reactstrap";

import LockUser from "./LockUser";
import ResetPassword from "./ResetPassword";
import JavascriptModeBot from "./JavascriptModeBot";
import JavascriptModeBotAPI from "./JavascriptModeAPI";
import Tokens from "./Tokens";
import TokensAPI from "./TokensAPI";
import MaxContainer from "./MaxContainer";

import { Gen_notification, DateDiff } from '../../../Constant';
import $ from "jquery"

$('body').on('click', '.k_save_settings', function(){
  var self_button_time = $(this);
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/save_option',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), name: 'expire', value: $('.k_expire').val()},
      success: function(data){
        console.log(data);
        if (data.status == 'success'){
          let currectdatetime = new Date().toISOString();

          var alerts = JSON.parse(localStorage.getItem('alerts'));
          var message = 'Настройки успешно сохранены';
          var type = 'success';

          var d1 = new Date(currectdatetime);
          var d2 = new Date();
          var dt_str = DateDiff.GenStr(d1, d2);

          Gen_notification.PushNotification(type, message, dt_str);
          alerts.push({'type': type, 'message': message, 'time': currectdatetime});
          localStorage.setItem('alerts', JSON.stringify(alerts));

          self_button_time.html('Сохранено!');
          //self_button_time.css('color', 'green');
          setTimeout(function(){
            self_button_time.html('Сохранить Настройки');
            //self_button_time.css('color', 'white');
          }, 2000);
        }
      }
  });

  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/save_option',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), name: 'api_out', value: $('#blah2 textarea').val()},
  });

  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/save_option',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), name: 'api_in', value: $('#blah3 textarea').val()},
  });

})


const SettingsUser = () => {
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/get_option',
      method: 'post',
      dataType: 'json',
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), name: 'expire'},
      success: function(data){
        console.log(data);
        if (data.status == 'success'){
          setTimeout(function(){
            $('.k_expire').val(data.value);
          }, 500);
        }
      }
  });

  return (
    <Fragment>
      <Breadcrumbs parent="Основное" title="Настройки" mainTitle="Настройки" />
      <Container fluid={true}>
        <Row>
          <div className="col-xxl-4 col-md-6 col-12">
            <Col className=" total-user">
              <LockUser />
            </Col>

            <Col className=" total-user">
              <Tokens />
            </Col>
          </div>

          <Col className="col-xxl-4 col-md-6 col-12 total-user">
            <ResetPassword />
          </Col>

          <Col className="col-xxl-4 col-lg-12 col-12">
           <Row>
            <Col className="col-xxl-12 col-md-6 col-12 total-user">
              <TokensAPI />
            </Col>

            <Col className="col-xxl-12 col-md-6 col-12 total-user max-container">
              <MaxContainer />
            </Col>
           </Row>
          </Col>
        </Row>

        <Row>
          <Col className="col-xxl-6 col-12">
            <JavascriptModeBot />
          </Col>

          <Col className="col-xxl-6 col-12">
            <JavascriptModeBotAPI />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SettingsUser;
