import React from "react";

import { UserMinus, UserPlus } from "react-feather";
import { Card, CardBody,FormGroup, CardHeader, Row, Label, Input } from "reactstrap";
import { UL, LI, H4, H5, H6 } from "../../../AbstractElements";
import { TotalUsers, ActivesDropdown, TimeType } from "../../../Constant";
import DropdownCommon2 from "../../Common/Dropdown2";
import eye from "../../../assets/images/auth/eye.svg";
import eyeoff from "../../../assets/images/auth/eye-off.svg";

const Tokens = () => {
  const [dropDown, setDropDown] = React.useState("не активирован");

  React.useEffect(() => {
    setDropDown("не активирован");
  }, []);

  function handlerActive(toogleDown) {
    setDropDown(toogleDown);
  }

  const [togglePassword2, setTogglePassword2] = React.useState(false);

  // function handleInputChange() {} 

  return (
    <Card>
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            Телеграм бот <span className={(dropDown === 'активирован' ? "h5 badge badge-light-success" : "h5 badge badge-light-danger")} >{dropDown}</span>
          </H5>
          <DropdownCommon2
            dropdownMain={{ className: "icon-dropdown", direction: "start" }}
            options={ActivesDropdown}
            iconName="icon-more-alt"
            btn={{ tag: "span" }}
            active={handlerActive}
          />
        </div>
      </CardHeader>
      <CardBody className="py-lg-3">
          <FormGroup className="position-relative">
              <div className="position-relative">
                <Input
                  className="form-control control-page"
                  type={togglePassword2 ? "text" : "password"}
                  name="login[password]"
                  required=""
                  placeholder="*********"
                />
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword2(!togglePassword2)}
                >
                  {!togglePassword2 && <img src={eye} alt="" />}
                  {togglePassword2 && <img src={eyeoff} alt="" />}
                </div>
              </div>
            </FormGroup>
      </CardBody>
    </Card>
  );
};

export default Tokens;
