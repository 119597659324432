import React, { Fragment, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H5, P } from "../../../AbstractElements";
import eye from "../../../assets/images/auth/eye.svg";
import eyeoff from "../../../assets/images/auth/eye-off.svg";

const ResetPassword = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);
  return (
    <Fragment>
      <div className="login-card p-0 mb-3 h-auto ">
        <div className="w-100">
          <div className="login-main w-100">
            <Form className="theme-form login-form">
              <H5>Изменить пароль</H5>
              <FormGroup className="mt-4 position-relative">
                <Label className="m-0 col-form-label">Новый пароль</Label>
                <div className="position-relative">
                  <Input
                    className="form-control control-page"
                    type={togglePassword ? "text" : "password"}
                    name="login[password]"
                    required
                    placeholder="*********"
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    {!togglePassword && <img src={eye} alt="" />}
                    {togglePassword && <img src={eyeoff} alt="" />}
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="m-0 col-form-label">Повторить пароль</Label>
                <div className="position-relative">
                  <Input
                    className="form-control control-page"
                    type={togglePassword2 ? "text" : "password"}
                    name="login[password]"
                    required=""
                    placeholder="*********"
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword2(!togglePassword2)}
                  >
                    {!togglePassword2 && <img src={eye} alt="" />}
                    {togglePassword2 && <img src={eyeoff} alt="" />}
                  </div>
                </div>
              </FormGroup>

            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
