import { Widgets2ChartData, Widgets2ChartData2, Widgets2ChartData2Widgets, Widgets2ChartDataWidgets } from './Chart';
import $ from 'jquery'

export const WidgetsData = {
  title: 'Purchase',
  gros: 50,
  total: 10_000,
  color: 'secondary',
  icon: 'cart',
};
export const WidgetsData2 = {
  title: 'Sales return',
  gros: 20,
  total: 7000,
  color: 'warning',
  icon: 'return-box',
};
export const WidgetsData3 = {
  title: 'Sales',
  gros: 70,
  total: 4_200,
  color: 'primary',
  icon: 'tag',
};
export const WidgetsData4 = {
  title: 'Purchase rate',
  gros: 70,
  total: 5700,
  color: 'success',
  icon: 'rate',
};

var bots = 0;
var bots_active = 0;
var bots_block = 0;
var bots_default = 0;
var bots_active_b = 0;
$.ajax({
    url: 'https://bots.qastudio-monitor.com:8100/get_users',
    method: 'post',
    dataType: 'json',
    async: false,
    data: {email: localStorage.getItem('email'), password: localStorage.getItem('password')},
    success: function(data){
      console.log(data);
      if (data.status == 'ok'){

        for (var i=0; i<data.users.length; i++) {
            bots+=1;

            if (data.users[i].user_status != '-1' || data.users[i].user_status != -1){
              bots_active+=1;
            }

            if (data.users[i].user_status == '-1' || data.users[i].user_status == -1){
              bots_block+=1;
            }

            if (data.users[i].user_status != '1' || data.users[i].user_status != 1){
              bots_default+=1;
            }

            if (data.users[i].user_status == '1' || data.users[i].user_status == 1){
              bots_active_b+=1;
            }
        }


        console.log(data.users);
      }
    }
});

export const WidgetsData1WidgetsPage = [
  {
    title: 'Всего пользователей',
    gros: 50,
    total: bots,
    color: 'warning',
    icon: 'users',
  },
  {
    title: 'Активных пользователей',
    gros: 70,
    total: bots_active,
    color: 'success',
    icon: 'user-visitor',
  },
  {
    title: 'Заблокированных пользователей',
    gros: 20,
    total: bots_block,
    color: 'secondary',
    icon: 'fill-user',
  },
  {
    title: 'Всего ботов',
    gros: 50,
    total: bots,
    color: 'primary',
    icon: 'bot',
  },
  {
    title: 'Активных ботов',
    gros: 70,
    total: bots_active_b,
    color: 'success',
    icon: 'bot-on',
  },
  
  {
    title: 'Неактивных ботов',
    gros: 20,
    total: bots_default,
    color: 'secondary',
    icon: 'bot-off',
  },
  
];

export const Widgets2Data = {
  title: 'Orders',
  total: '1,80k',
  chart: Widgets2ChartData,
};
export const Widgets2Data2 = {
  title: 'Profit',
  total: '6,90k',
  chart: Widgets2ChartData2,
};
export const Widgets2DataWidgets = {
  title: 'Orders',
  total: '1,80k',
  chart: Widgets2ChartDataWidgets,
};
export const Widgets2Data2Widgets = {
  title: 'Profit',
  total: '6,90k',
  chart: Widgets2ChartData2Widgets,
};

export const LightCardData = [
  {
    title: 'Income',
    icon: 'income',
    price: '$22,678',
    gros: '+$456',
    color: 'success',
  },
  {
    title: 'Expense',
    icon: 'expense',
    price: '$12,057',
    gros: '+$256',
    color: 'danger',
  },
  {
    title: 'Cashback',
    icon: 'doller-return',
    price: '$8,475',
  },
];
export const LightCardData2 = [
  {
    title: 'Orders',
    icon: 'orders',
    price: '10,098',
    color: 'success',
  },
  {
    title: 'Earning',
    icon: 'expense',
    price: '$12,057',
    color: 'danger',
  },
  {
    title: 'Refunds',
    icon: 'doller-return',
    price: '3,001',
  },
];

export const ActivityData = [
  {
    title: 'Updated Product',
    subTitle: '8th March, 2022',
    time: '1 day ago',
    dis: 'Quisque a consequat ante sit amet magna...',
    color: 'primary',
  },
  {
    title: 'Tello just like your product',
    subTitle: '15th Oct, 2022 ',
    time: 'Today',
    dis: 'Quisque a consequat ante sit amet magna...',
    color: 'warning',
  },
  {
    title: 'Tello just like your product',
    subTitle: '20th Sep, 2022',
    time: '12:00 PM',
    dis: 'Quisque a consequat ante sit amet magna...',
    color: 'secondary',
  },
];

export const RecentSalesData = [
  {
    image: '1.jpg',
    title: 'Jane Cooper',
    subTitle: '10 minutes ago',
    badge: '$200.00',
  },
  {
    image: '2.jpg',
    title: 'Brooklyn Simmons',
    subTitle: '19 minutes ago',
    badge: '$970.00',
  },
  {
    image: '3.jpg',
    title: 'Leslie Alexander',
    subTitle: '2 hours ago',
    badge: '$300.00',
  },
  {
    image: '4.jpg',
    title: 'Travis Wright',
    subTitle: '8 hours ago',
    badge: '$450.00',
  },
  {
    image: '5.jpg',
    title: ' Mark Green',
    subTitle: '1 day ago',
    badge: '$768.00',
  },
];

export const NoteLabels = [
  {
    title: 'SAAS',
    color: 'primary',
  },
  {
    title: 'E-Commerce',
    color: 'success',
  },
  {
    title: 'Crypto',
    color: 'warning',
  },
  {
    title: 'Project',
    color: 'info',
  },
  {
    title: 'NFT',
    color: 'secondary',
  },
  {
    title: '+9',
    color: 'light',
  },
];
