import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { FollowersGrowthRu, WeeklyMonDropdown } from "../../../Constant";
import {
  UserChartDataResponsive,
  UserChartTimeData,
  UserChartTimeDataResponsive,
} from "../../../Data/DefaultDashboard/Chart";

import ReactApexChart from "react-apexcharts";
import DropdownCommon2 from "../../Common/Dropdown2";

import $ from "jquery";

const FollowerGrowth = () => {
  var mounth = [];
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/get_user_activity',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), user_id: window.location.pathname.split(':')[1]},
      success: function(data){
        console.log(data);
        if (data.status == 'success'){
          for (var i=0; i<12; i++) {
            mounth[i] = 0;
          }
          for (var i=0; i<data.activity_arr.length; i++) {
            var d1 = new Date(data.activity_arr[i].date);
            mounth[d1.getUTCMonth()] += 1;
          }
        }
      }
  });
  const UserChartData = {
    series: [
      {
        name: 'Соединений в день',
        data: mounth,
      },
    ],
    options: {
      chart: {
        height: 200,
        type: 'line',
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 4,
          color: '#7366ff',
          opacity: 0.22,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: ['#5527FF'],
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: ['Янв', 'Февр', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        tickAmount: 10,
        labels: {
          style: {
            fontFamily: 'Rubik, sans-serif',
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#5527FF'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: '#5527FF',
              opacity: 1,
            },
            {
              offset: 100,
              color: '#E069AE',
              opacity: 1,
            },
          ],
        },
      },
      yaxis: {
        min: -10,
        max: 40,
        labels: {
          show: false,
        },
      },
    },
  };


  const updatedChartData = {
    ...UserChartData,
    options: {
      ...UserChartData.options,
      ...UserChartDataResponsive,
    },
  };

  const updatedChartTimeData = {
    ...UserChartTimeData,
    options: {
      ...UserChartTimeData.options,
      ...UserChartTimeDataResponsive,
    },
  };

  const [dropDown, setDropDown] = React.useState("в днях");

  React.useEffect(() => {
    setDropDown("в днях");
  }, []);

  function handlerActive(toogleDown) {
    setDropDown(toogleDown);
  }

  return (
    <Card className="card growth-wrap">
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            Нагрузка на сервер <span className="h5 badge badge-light-success">{dropDown}</span>
          </H5>
          <DropdownCommon2
            dropdownMain={{ className: "icon-dropdown", direction: "start" }}
            options={WeeklyMonDropdown}
            iconName="icon-more-alt"
            btn={{ tag: "span" }}
            active={handlerActive}
          />
        </div>
      </CardHeader>
      <CardBody>
        <div className="growth-wrapper">
          <ReactApexChart
            height={325}
            type="line"
            options={(dropDown === 'в днях') ? updatedChartData.options : updatedChartTimeData.options}
            series={(dropDown === 'в днях') ? updatedChartData.series : updatedChartTimeData.series}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowerGrowth;
