import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authHeader, handleResponse } from '../Services/fack.backend';

const PrivateRoute = () => {
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem('login')));
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem('token');

  useEffect(() => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch('/users', requestOptions).then(handleResponse);
    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));

    localStorage.setItem('authenticated', authenticated);
    localStorage.setItem('login', login);
  }, []);

  var k_test = false;
  var url_test = window.location.pathname.split('/')[1];
  if (url_test == 'statistics'){
    k_test = true;
  }else{
    if (login || authenticated || jwt_token){
      k_test = true;
    }else{
      k_test = false;
    }
  }

  if (login || authenticated || jwt_token){}else{
    var styles = `
      .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li + li.sidebar-main-title + li.sidebar-list,
      li.onhover-dropdown,
      ul.sidebar-submenu {
          display: none !important;
      }
    `
    var styleSheet = document.createElement("style")
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  }

  return k_test ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;
