import { H5 } from "../../../AbstractElements";
//import { LogBota } from "./Data";
import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import $ from "jquery"

const JavascriptModeBotAPI = () => {
var LogBota = ''
$.ajax({
    url: 'https://bots.qastudio-monitor.com:8100/get_user_log',
    method: 'post',
    dataType: 'json',
    async: false,
    data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), user_id: window.location.pathname.split(':')[1]},
    success: function(data){
      console.log(data);
      console.log('bb2');
      if (data.status == 'ok'){
        console.log('bb3');
        var data_status = data.user_status;
        setTimeout(function(){
          if (data_status == -1) {
            console.log('bb');
            $('.k_user_active').css('display', 'none');
            $('.k_user_block').css('display', 'block');
            $('.k_user_none').css('display', 'none');
          }
          if (data_status == 0) {
            console.log('bb1');
            $('.k_user_active').css('display', 'none');
            $('.k_user_block').css('display', 'none');
            $('.k_user_none').css('display', 'block');
          }
          if (data_status == 1) {
            console.log('bb1');
            $('.k_user_active').css('display', 'block');
            $('.k_user_block').css('display', 'none');
            $('.k_user_none').css('display', 'none');
          }
        }, 500);
        setTimeout(function(){
          var editor = window.ace.edit("blah5");
          editor.setValue(data.main_log, -1);
        }, 500);
      }
    }
});
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H5>LOG Бота</H5>
        </CardHeader>
        <CardBody>
          <AceEditor
            style={{
              marginTop: "0px",
              width: "663px",
              height: "428px",
              marginLeft: "0px",
            }}
            mode="javascript"
            theme="monokai"
            value={LogBota}
            name="blah5"
            setOptions={{ useWorker: false }}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            editorProps={{ $blockScrolling: true }}
            highlightActiveLine={true}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default JavascriptModeBotAPI;
