import { Btn } from '../../../../AbstractElements';
import { SettingsUserName, Submit, TimeType } from '../../../../Constant';
import React, { Fragment } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';

const NetBankings = () => {
  return (
    <Fragment>
      <Col lg='6' xxl='4' className='box-col-6'>
        <Card>
          <HeaderCard title={SettingsUserName} />
          <CardBody>
            <Row className='theme-form'>

              <FormGroup className='mb-3 col-12'>
                <Label>Время жизни пользователя</Label>
                <Input className='form-control' type='number' />
              </FormGroup>
              <FormGroup className='mb-3 col-12'>
                <Label>Тип времени</Label>
                <select className='form-control' size='1'>
                  {TimeType.map((name, i) => (
                    <option key={i}>{name}</option>
                  ))}
                </select>
              </FormGroup>

              <div className='col-12 mt-3 w-100'>
                <Btn attrBtn={{ color: 'primary', className: 'btn btn-primary d-block mx-auto' }}>Сохранить настройки</Btn>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default NetBankings;
