import React from "react";

import { UserMinus, UserPlus } from "react-feather";
import { Card, CardBody, CardHeader, Row, Label, Input } from "reactstrap";
import { UL, LI, H4, H5, H6 } from "../../../AbstractElements";
import { TotalUsers, WeeklyMonDropdown, TimeType } from "../../../Constant";
import DropdownCommon2 from "../../Common/Dropdown2";

const LockUser = () => {
  const [dropDown, setDropDown] = React.useState("в днях");

  React.useEffect(() => {
    setDropDown("в днях");
  }, []);

  function handlerActive(toogleDown) {
    setDropDown(toogleDown);
  }

  function handleInputChange() {}

  return (
    <Card>
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            Блокировка <span className="badge badge-light-success">{dropDown}</span>
          </H5>
          <DropdownCommon2
            dropdownMain={{ className: "icon-dropdown", direction: "start" }}
            options={WeeklyMonDropdown}
            iconName="icon-more-alt"
            btn={{ tag: "span" }}
            active={handlerActive}
          />
        </div>
      </CardHeader>
      <CardBody className="py-lg-3">
        <div className="user-list d-flex justify-content-center align-items-center gap-2">
          <div className="user-icon primary">
            <div className="user-box">
              <UserPlus className="font-primary" />
            </div>
          </div>
          <div className="input-number">
            <Input
              className="form-control k_expire"
              type="number"
              min="0"
              max="365"
              placeholder="100"
              required
              onChange={handleInputChange}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LockUser;
