import { H5 } from "../../../AbstractElements";
import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import $ from 'jquery';

const JavascriptModeBot = () => {
  function onChange(newValue) {
    $('#blah2 textarea').val(newValue);
    console.log("change", newValue);
  }

  var JavadataOut = '';
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/get_option',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), name: 'api_out'},
      success: function(data){
        console.log(data);

        if (data.status == 'success'){
          setTimeout(function(){
            var editor = window.ace.edit("blah2");
            editor.setValue(data.value, -1);
          }, 500);
        }
      }
  });


  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H5>Запросы отправка</H5>
        </CardHeader>
        <CardBody>
          <AceEditor
            style={{
              marginTop: "0px",
              width: "663px",
              height: "428px",
              marginLeft: "0px",
            }}
            mode="javascript"
            theme="monokai"
            value={JavadataOut}
            onChange={onChange}
            name="blah2"
            setOptions={{ useWorker: false }}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            editorProps={{ $blockScrolling: true }}
            highlightActiveLine={true}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default JavascriptModeBot;
