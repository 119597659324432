import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';

import FollowerGrowth from './FollowerGrowth';
import FollowerBot from './FollowerBot';

import JavascriptModeBot from './JavascriptModeBot';
import JavascriptModeAPI from './JavascriptModeAPI';


const StatUser = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Бот' parent='Статистика' title='Страница пользователя' />
      <Container fluid={true} className='general-widget'>
      
        <Row>
          <Col xxl='6' xl='6' md='6' sm='12' className='box-col-12'>
            <FollowerGrowth />
          </Col>

          <Col xxl='6' xl='6' md='6' sm='12' className='box-col-12'>
            <FollowerBot />
          </Col>
        </Row>

        <Row>
          <Col className="col-lg-6 col-12">
              <JavascriptModeBot />
            </Col>

            <Col className="col-lg-6 col-12">
              <JavascriptModeAPI />
            </Col>
        </Row>

      </Container>
    </Fragment>
  );
};

export default StatUser;
