import React from "react";

import { UserMinus, UserPlus } from "react-feather";
import { Card, CardBody, CardHeader, Row, Label, Input } from "reactstrap";
import { UL, LI, H4, H5, H6 } from "../../../AbstractElements";
import SvgIcon from '../../Common/Component/SvgIcon';


const MaxContainer = () => {

  return (
    <Card>
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            Объем контейнера
          </H5>
        </div>
      </CardHeader>
      <CardBody className="py-lg-3">
        <div className="user-list d-flex justify-content-center align-items-center gap-2">
        <div className="user-icon primary">
            <div className="user-box">
              <SvgIcon iconId={'new-order'} className='stroke-icon svg-fill' />
            </div>
          </div>
          <div className="input-number">
            <Input
              className="form-control"
              type="number"
              min="0"
              max="365"
              placeholder="200"
              required
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MaxContainer;
