import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';

import { WidgetsData1WidgetsPage } from '../../../Data/DefaultDashboard';

import Widgets1 from '../../Common/CommonWidgets/Widgets1';

import FollowerGrowth from './FollowerGrowth';
import FollowerBot from './FollowerBot';
import BasicAreaChartClass from './BasicAreaChartClass';

import $ from 'jquery';

const GeneralComponent = () => {
  var bots = 0;
  var bots_active = 0;
  var bots_block = 0;
  var bots_default = 0;
  var bots_active_b = 0;
  $.ajax({
      url: 'https://bots.qastudio-monitor.com:8100/get_users',
      method: 'post',
      dataType: 'json',
      async: false,
      data: {email: localStorage.getItem('email'), password: localStorage.getItem('password')},
      success: function(data){
        console.log(data);
        if (data.status == 'ok'){

          for (var i=0; i<data.users.length; i++) {
              bots+=1;

              if (data.users[i].user_status != '-1' || data.users[i].user_status != -1){
                bots_active+=1;
              }

              if (data.users[i].user_status == '-1' || data.users[i].user_status == -1){
                bots_block+=1;
              }

              if (data.users[i].user_status != '1' || data.users[i].user_status != 1){
                bots_default+=1;
              }

              if (data.users[i].user_status == '1' || data.users[i].user_status == 1){
                bots_active_b+=1;
              }
              
          }


          console.log(data.users);
        }
      }
  });

  const WidgetsData1WidgetsPage = [
    {
      title: 'Всего пользователей',
      gros: 50,
      total: bots,
      color: 'warning',
      icon: 'users',
    },
    {
      title: 'Активных пользователей',
      gros: 70,
      total: bots_active,
      color: 'success',
      icon: 'user-visitor',
    },
    {
      title: 'Заблокированных пользователей',
      gros: 20,
      total: bots_block,
      color: 'secondary',
      icon: 'fill-user',
    },
    {
      title: 'Всего ботов',
      gros: 50,
      total: bots,
      color: 'primary',
      icon: 'bot',
    },
    {
      title: 'Активных ботов',
      gros: 70,
      total: bots_active_b,
      color: 'success',
      icon: 'bot-on',
    },
    
    {
      title: 'Неактивных ботов',
      gros: 20,
      total: bots_default,
      color: 'secondary',
      icon: 'bot-off',
    },
    
  ];


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Сводная статистика' parent='Статистика' title='Сводная' />
      <Container fluid={true} className='general-widget'>
        <Row>
          <Col xxl='12' xl='12'  className='box-col-12'>
            <Row>
              {WidgetsData1WidgetsPage.map((item, i) => (
                <Col key={i} sm='12' md='6' xl='4' className='box-col-4'>
                  <Widgets1 data={item} />
                </Col>
              ))}
            </Row>
          </Col>

          <Col xxl='6' xl='6' md='6' sm='12' className='box-col-12'>
            <FollowerGrowth />
          </Col>

          <Col xxl='6' xl='6' md='6' sm='12' className='box-col-12'>
            <FollowerBot />
          </Col>

          {/*<Col  sm='12' className='box-col-12'>
            <BasicAreaChartClass />
          </Col>*/}

        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
