import React, { Fragment } from 'react';
import NetBankings from './NetBanking';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';

const PaymentDetailContain = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Основное" title="Настройки" mainTitle="Настройки" />
            <Container fluid={true} className="credit-card">
                <Row>
                    {/* <CreditCards /> */}
                    {/* <DebitCards /> */}
                    {/* <CashonDelivery /> */}
                    {/* <EMIs /> */}
                    <NetBankings />
                </Row>
            </Container>
        </Fragment>
    );
};
export default PaymentDetailContain;