import { H5 } from "../../../AbstractElements";
//import { Javadata } from "./Data";
import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import $ from "jquery";

const JavascriptModeBot = () => {
var Javadata = ''
$.ajax({
    url: 'https://bots.qastudio-monitor.com:8100/get_user_log',
    method: 'post',
    dataType: 'json',
    async: false,
    data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), user_id: window.location.pathname.split(':')[1]},
    success: function(data){
      console.log(data);
      if (data.status == 'ok'){
        setTimeout(function(){
          var editor = window.ace.edit("blah4");
          editor.setValue(data.con_log, -1);
        }, 500);
      }
    }
});
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H5>LOG Docker-контейнера</H5>
        </CardHeader>
        <CardBody>
          <AceEditor
            style={{
              marginTop: "0px",
              width: "663px",
              height: "428px",
              marginLeft: "0px",
            }}
            mode="javascript"
            theme="monokai"
            value={Javadata}
            name="blah4"
            setOptions={{ useWorker: false }}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            editorProps={{ $blockScrolling: true }}
            highlightActiveLine={true}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default JavascriptModeBot;
