import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { EmailAddressRu, ForgotPasswordRu, LoginRu, LoginWithJWTRu, PasswordRu, RememberPasswordRu, SignInRu } from '../../../Constant';

import { useNavigate } from 'react-router-dom';
import { Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/profile.png';
import { handleResponse } from '../../../Services/fack.backend';

import CustomizerContext from '../../../_helper/Customizer';
import eye from "../../../assets/images/auth/eye.svg";
import eyeoff from "../../../assets/images/auth/eye-off.svg";

import $ from 'jquery';

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState('test@gmail.com');
  const [password, setPassword] = useState('test123');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [value, setValue] = useState(localStorage.getItem('profileURL' || man));
  const [name, setName] = useState(localStorage.getItem('Name'));

  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Emay Walter');
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setValue(man);
    setName('Emay Walter');
    if (email !== '' && password !== '') {
      $.ajax({
        url: 'https://bots.qastudio-monitor.com:8100/api_login',
        method: 'post',
        dataType: 'json',
        data: {email: email, password: password},
        success: function(data){
          if (data.status == 'ok'){
            localStorage.setItem('login', JSON.stringify(true));

            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
            history(`${process.env.PUBLIC_URL}/statistics/general/${layoutURL}`);
          }else{
            $('.k_error').fadeIn();
          }
        }
      });


    }
  };

  const loginWithJwt = (e) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { email, password },
    };

    return fetch('/users/authenticate', requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        setName('Emay Walter');
        localStorage.setItem('token', Jwt_token);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`;
        return user;
      });
  };

  

  return (
    <Fragment>
      <Form className='theme-form'>
        <H4>{selected === 'simpleLogin' ? 'Войти' : 'Войти через Jwt'}</H4>

        <FormGroup>
          <Label className='col-form-label'>{LoginRu}</Label>
          {/* value={email} */}
          <Input className='form-control' type='text' onChange={(e) => setEmail(e.target.value)} placeholder='Логин' />   
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{PasswordRu}</Label>
          <div className='position-relative'>
            <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              {!togglePassword && <img src={eye} alt="" />}
              {togglePassword && <img src={eyeoff} alt="" />}
            </div>
          </div>
        </FormGroup>
        <p className="k_error">Неверный логин или пароль!</p>
        <div className='position-relative form-group mb-0'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPasswordRu}
            </Label>
          </div>
          {/* <a className='link' href='#javascript'>
            {ForgotPasswordRu}
          </a> */}
          {selected === 'simpleLogin' ? (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginAuth(e) }}>{SignInRu}</Btn>
          ) : (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginWithJwt(e) }}>{LoginWithJWTRu}</Btn>
          )}
        </div>

      </Form>
    </Fragment>
  );
};

export default LoginTab;
