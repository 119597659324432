import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { FollowersBotRu, WeeklyMonDropdown } from "../../../Constant";
import {
  BotChartData,
  BotChartDataResponsive,
  BotChartTimeData,
  BotChartTimeDataResponsive
} from "../../../Data/DefaultDashboard/Chart";

import ReactApexChart from "react-apexcharts";
import DropdownCommon2 from "../../Common/Dropdown2";

const FollowerBot = () => {
  const updatedChartBotData = {
    ...BotChartData,
    options: {
      ...BotChartData.options,
      ...BotChartDataResponsive,
    },
  };

  const updatedChartBotTimeData = {
    ...BotChartTimeData,
    options: {
      ...BotChartTimeData.options,
      ...BotChartTimeDataResponsive,
    },
  };

  const [dropDown, setDropDown] = React.useState("в днях");

  React.useEffect(() => {
    setDropDown("в днях");
  }, []);

  function handlerActive(toogleDown) {
    setDropDown(toogleDown);
  }

  return (
    <Card className="card growth-wrap">
      <CardHeader className="card-no-border pb-0">
        <div className="header-top">
          <H5>
            {FollowersBotRu} <span className="h5 badge badge-light-success">{dropDown}</span>
          </H5>
          <DropdownCommon2
            dropdownMain={{ className: "icon-dropdown", direction: "start" }}
            options={WeeklyMonDropdown}
            iconName="icon-more-alt"
            btn={{ tag: "span" }}
            active={handlerActive}
          />
        </div>
      </CardHeader>
      <CardBody>
        <div className="growth-wrapper">
          <ReactApexChart
            height={325}
            type="line"
            options={(dropDown === 'в днях') ? updatedChartBotData.options : updatedChartBotTimeData}
            series={(dropDown === 'в днях') ? updatedChartBotData.series : updatedChartBotTimeData.series}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowerBot;
