import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Gen_notification, DateDiff } from '../../../Constant';

$('body').on('click', '.k_block_user', function(){
    var button_self = $(this);
    if ($(this).html() == 'Заблокировать'){
        $.ajax({
            url: 'https://bots.qastudio-monitor.com:8100/user_block',
            method: 'post',
            dataType: 'json',
            data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), user_id: $(this).attr('data-id')},
            success: function(data){
              console.log(data);
              if (data.status == 'success'){
                $('.k_change_badge').each(function(){
                    if ($(this).attr('data-id') == button_self.attr('data-id')){
                        $(this).addClass('badge-light-danger');
                        $(this).removeClass('badge-light-success');
                        $(this).removeClass('badge-light-warning');
                        $(this).html('Заблокирован');
                    }
                });

                let currectdatetime = new Date().toISOString();

                var alerts = JSON.parse(localStorage.getItem('alerts'));
                var message = 'Пользователь '+button_self.attr('data-username')+' заблокирован!';
                var type = 'info';

                var d1 = new Date(currectdatetime);
                var d2 = new Date();
                var dt_str = DateDiff.GenStr(d1, d2);

                Gen_notification.PushNotification(type, message, dt_str);
                alerts.push({'type': type, 'message': message, 'time': currectdatetime});
                localStorage.setItem('alerts', JSON.stringify(alerts));

                button_self.html('Разблокировать');
              }
            }
        });
    }else{
        $.ajax({
            url: 'https://bots.qastudio-monitor.com:8100/user_unblock',
            method: 'post',
            dataType: 'json',
            data: {email: localStorage.getItem('email'), password: localStorage.getItem('password'), user_id: $(this).attr('data-id')},
            success: function(data){
              console.log(data);
              if (data.status == 'success'){
                $('.k_change_badge').each(function(){
                    if ($(this).attr('data-id') == button_self.attr('data-id')){
                        $(this).removeClass('badge-light-danger');
                        $(this).removeClass('badge-light-success');
                        $(this).addClass('badge-light-warning');
                        $(this).html('Не активен');
                    }
                });

                let currectdatetime = new Date().toISOString();

                var alerts = JSON.parse(localStorage.getItem('alerts'));
                var message = 'Пользователь '+button_self.attr('data-username')+' разблокирован!';
                var type = 'info';

                var d1 = new Date(currectdatetime);
                var d2 = new Date();
                var dt_str = DateDiff.GenStr(d1, d2);

                Gen_notification.PushNotification(type, message, dt_str);
                alerts.push({'type': type, 'message': message, 'time': currectdatetime});
                localStorage.setItem('alerts', JSON.stringify(alerts));

                button_self.html('Заблокировать');
              }
            }
        });
    }
});

export const tableColumns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        center: true,
    },
    {
        name: 'Пользователь',
        selector: row => row.user,
        sortable: true,
        center: false,
    },
    {
        name: 'Пользователь',
        selector: row => row.links,
        sortable: true,
        center: false,
    },
    {
        name: 'Статус',
        selector: row => row['status'],
        sortable: false,
        center: true,
    },
    {
        name: 'Статус',
        selector: row => row['status_sort'],
        sortable: true,
        center: true,
    },
    {
        name: 'Дата регистрации',
        selector: row => row['date_reg'],
        sortable: false,
        center: true,
    },
    {
        name: 'Дата блокировки',
        selector: row => row['date_block'],
        sortable: false,
        center: true,
    },
    {
        name: 'Контроль',
        selector: row => row['control'],
        sortable: false,
        center: true,
    },


];